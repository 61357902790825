import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Hero from '../components/hero';
import Layout from '../components/layout-art';
import usePosts from '../hooks/use-posts';
import PostPreview from '../components/post-preview';
import Toggle from '../components/toggleRPC';
import Modal from '../modal';

export default () => {
  const posts = usePosts();
  return (
    <>
      <Hero />
      <Layout>
        <Toggle>
          {({ on, toggle }) => (
            <>
              <button onClick={toggle}>Close Up</button>
              <Modal on={on} toggle={toggle}>
                <h3>Coming at u from a Modal</h3>
              </Modal>
            </>
          )}
        </Toggle>

        {/* <Portal>
          <h3>Coming 2 u from a Portal</h3>
        </Portal> */}

        {posts.map(post => (
          <PostPreview key={post.slug} post={post} />
        ))}
      </Layout>
    </>
  );
};
